// eslint-disable-next-line import/no-cycle
import { TYPE_SLIDE_PREFIX } from '../../pages/ContentEditor/partials/Ck5Editor/plugins/LinkPlugin';

export function getSlideAndStudioId(isHackRoomLesson, url) {
  // see which type of slide link it is
  const isPlatformLink = url.includes('slideviewer');
  const isStudioLink =
    (url.includes('lesson') && !isHackRoomLesson) || url.includes('content');
  const slideLinkParts = url.replace(TYPE_SLIDE_PREFIX, '').split('/');

  // note links can be optionally be prefixed by slide:// if that is between ()
  // hackroom example: (slide://)http://localhost:5000/slideviewer/hackroom/lesson/2796/classical/2036671
  // studioLink example: (slide://)http://localhost:3000/content/3120/projection/2036884
  if (isHackRoomLesson || isStudioLink) {
    return {
      slideId: slideLinkParts.at(-1),
      studioId: slideLinkParts.at(-3),
    };
  }

  // eg: (slide://)http://localhost:5000/slideviewer/2801/projection/1cc3b91a-bfb5-4831-b6eb-f849c6002801/2034726
  if (isPlatformLink) {
    return {
      slideId: slideLinkParts.at(-1),
      studioId: slideLinkParts.at(-4),
    };
  }

  // if it's neither a platform nor a studio link ... aka a slide://<slideID>/<studioId>
  return {
    slideId: slideLinkParts.at(0),
    studioId: slideLinkParts.at(1),
  };
}
