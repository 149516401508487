import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import {
  generateQuestionAnswersState,
  SlideViewerContextProvider,
  StyledSlideViewer,
  usePrismTheme,
  VIEW_MODES,
} from '@ftrprf/tailwind-components';

import { UserContext } from 'providers/UserProvider';

import useTitle from 'hooks/useTitle';
import URLS from 'utils/constants/urls';

const generateScratchExercisePath = ({ versionId }) => {
  if (!versionId) {
    return '';
  }
  return generatePath(`${URLS.EXERCISE}/version/:versionId`, { versionId });
};

export function SlideViewer({ data, error, loading }) {
  const navigate = useNavigate();
  const { lessonContentId, slideId, viewMode: defaultViewMode } = useParams();

  const [currentSlideId, setCurrentSlideId] = useState(slideId);

  const user = useContext(UserContext);
  const { organization, settings } = user;
  const [submittedQuestionAnswers, setSubmittedQuestionAnswers] = useState([]);

  const [viewMode, setViewMode] = useState(
    VIEW_MODES[defaultViewMode?.toUpperCase() || VIEW_MODES.CLASSICAL],
  );
  usePrismTheme(settings?.slideViewer?.codeBlocksTheme);

  const lesson = useMemo(
    () => data?.findLessonContent,
    [data?.findLessonContent],
  );

  useTitle(lesson?.title);

  const onSlideChange = useCallback(
    (newSlideId) => {
      if (newSlideId && newSlideId !== currentSlideId) {
        setCurrentSlideId(newSlideId);
      }
    },
    [currentSlideId],
  );

  const filteredSlides = useMemo(
    () =>
      lesson?.slides.filter((slide) => slide.viewModes.includes(viewMode)) ||
      [],
    [lesson, viewMode],
  );

  useEffect(() => {
    if (slideId !== currentSlideId && filteredSlides && lesson) {
      const lessonContainsSlideForViewMode = Boolean(
        filteredSlides.filter((slide) => slide.id === slideId).length,
      );

      if (lessonContainsSlideForViewMode) setCurrentSlideId(slideId);
      else setCurrentSlideId('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideId]);

  const onChangeViewMode = useCallback(
    (newViewMode) => {
      if (newViewMode && newViewMode !== viewMode) {
        setViewMode(newViewMode);
      }
    },
    [viewMode],
  );

  const onClose = () => {
    navigate(`${URLS[lesson.type]}/${lesson.id}/edit`, { replace: true });
  };

  // This generates the output that normally the API will generate
  const onSubmitQuestionAnswers = useCallback(
    (question, value) =>
      new Promise((resolve) => {
        const submittedQuestionAnswer = generateQuestionAnswersState(
          question,
          value,
        );

        setSubmittedQuestionAnswers((prev) => {
          const filteredSubmittedAnswers = prev.filter(
            ({ questionId }) => questionId !== question.id,
          );
          return [...filteredSubmittedAnswers, submittedQuestionAnswer];
        });

        resolve({
          question,
          submittedQuestionAnswer,
        });
      }),
    [],
  );

  if (loading || error || !lesson) {
    return null;
  }

  const generateCurrentLessonPath = ({ slideId }) => {
    if (!slideId) {
      return '';
    }

    return generatePath(URLS.CONTENT_PARAMS, {
      lessonContentId,
      slideId,
      viewMode: viewMode.toLowerCase(),
    });
  };

  const generateLessonPath = ({ slideId, studioId }) => {
    if (!slideId) {
      return '';
    }

    return generatePath(URLS.CONTENT_PARAMS, {
      lessonContentId: studioId,
      slideId,
      viewMode: viewMode.toLowerCase(),
    });
  };

  return (
    <SlideViewerContextProvider
      canEdit
      canOverrideAnswers={false}
      generateCurrentLessonPath={generateCurrentLessonPath}
      generateLessonPath={generateLessonPath}
      generateScratchExercisePath={generateScratchExercisePath}
      isCk5={lesson.editorVersion === 5}
      isCodefever={organization?.isCodefever}
      lesson={lesson}
      onChangeSlide={onSlideChange}
      onChangeViewMode={onChangeViewMode}
      onSubmitQuestionAnswers={onSubmitQuestionAnswers}
      preview
      setViewMode={setViewMode}
      showConfetti
      slideId={currentSlideId ?? filteredSlides[0]?.id}
      slides={filteredSlides}
      submittedQuestionAnswers={submittedQuestionAnswers}
      user={user}
      userDoesNotHaveSteams={organization?.steams === false}
      viewMode={viewMode}
    >
      <StyledSlideViewer onClose={onClose} showTeacherInfoTab showViewModes />
    </SlideViewerContextProvider>
  );
}
