import {
  SLIDEVIEWER_PREFIX,
  STYLED_BUTTON_CLASS,
} from '@ftrprf/tailwind-components';
import c from '../../../utils/functions/c';
import { availableLinkStyles } from '../../../utils/constants/availableLinkStyles';
import useFormatMessage from '../../../hooks/useFormatMessage';

export function LinkPreview({ selectedStyle, setSelectedStyleEnhanced }) {
  const t = useFormatMessage();

  return (
    <div className="flex flex-wrap justify-around items-center SlideViewerTextSlide">
      {Object.values(availableLinkStyles).map((style) => (
        <button
          key={style}
          className={c(
            STYLED_BUTTON_CLASS,
            'cursor-pointer select-none mb-2',
            `${SLIDEVIEWER_PREFIX}${style}`,
            style !== selectedStyle && 'opacity-25',
          )}
          onClick={() => setSelectedStyleEnhanced(style)}
          type="button"
        >
          {t(`link-plugin.link-style.${style}`)}
        </button>
      ))}
    </div>
  );
}
