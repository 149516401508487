import { useCallback, useEffect, useRef, useState } from 'react';
import Prism from 'prismjs';
import 'prismjs/plugins/filter-highlight-all/prism-filter-highlight-all.min';
import scratchblocks from 'scratchblocks';
import c from '../../utils/c';
// eslint-disable-next-line import/no-cycle
import SlideViewerQuestion from '../styled-slideviewer/partials/questions/SlideViewerQuestion';
import { ScrollIndicator } from './ScrollIndicator';
import { elementHasNoTextContent } from './elementHasNoTextContent';

const slideWidth = 1366;
const slideHeight = 768;

export function SlideViewerCk5Slide({
  children,
  currentSlide,
  hasFixedDimensions,
  id = '',
  isClickable = true,
  isFullSlideViewer = true,
  isThumbnail = false,
  isTransparent,
  shouldCenter = true,
}) {
  const contentElementRef = useRef();
  const [scale, setScale] = useState(1);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [question] = currentSlide?.questions || [];

  const calculateScale = useCallback(() => {
    const container = isThumbnail
      ? document.getElementsByClassName('slideThumbnail')[0]
      : document.getElementById(`slideViewerContentContainer${id}`);

    if (!container) return;
    const { clientHeight: containerHeight, clientWidth: containerWidth } =
      container;

    const scaleValue = Math.min(
      containerWidth / slideWidth,
      containerHeight / slideHeight,
    );

    setScale(scaleValue);

    if (isThumbnail) return;

    if (shouldCenter) {
      setTop((containerHeight - slideHeight * scaleValue) / 2);
      setLeft((containerWidth - slideWidth * scaleValue) / 2);
    }
  }, [id, isThumbnail, shouldCenter]);

  useEffect(() => {
    if (hasFixedDimensions) {
      calculateScale();
      addEventListener('resize', calculateScale);
    }
  }, [calculateScale, hasFixedDimensions]);

  useEffect(() => {
    if (isFullSlideViewer || isThumbnail) {
      // Scratch block generation
      const elements = document.querySelectorAll(
        'code.language-blocks:not(.styled)',
      );
      const options = {
        inline: false,
        languages: ['en'],
        scale: 1,
        style: 'scratch3',
      };
      if (elements.length > 0) {
        elements.forEach((el) => {
          el.classList.add('styled');
          const code = scratchblocks.read(el);
          const doc = scratchblocks.parse(code, options);
          const svg = scratchblocks.render(doc, options);
          scratchblocks.replace(el, svg, doc, options);
        });
      }

      // Syntax Highlighting
      Prism.plugins.filterHighlightAll.add((env) => env.language !== 'blocks');
      Prism.highlightAll();
    }
  }, [children, isFullSlideViewer, isThumbnail]);

  useEffect(() => {
    /**
     * the below code serves to ensure that the scroll-indicator isn't shown if
     * the content editors had fun adding empty p-tags with just a nbsp in them
     * at the bottom.
     */
    if (isFullSlideViewer) {
      let lastElementIsNotEmpty = true;

      while (lastElementIsNotEmpty) {
        const lastParagraph = contentElementRef.current.querySelector(
          '.ck5Slide > p:last-child, .ck5Slide > *:last-child > p:last-child',
        );
        const lastParagraphHasNoChildren =
          lastParagraph?.childElementCount === 0;

        if (
          lastParagraph &&
          lastParagraphHasNoChildren &&
          elementHasNoTextContent(lastParagraph)
        ) {
          lastParagraph.remove();
        } else {
          lastElementIsNotEmpty = false;
        }
      }
    }
  }, [children, isFullSlideViewer]);

  return (
    <>
      <div
        className={c(
          'slideviewerCK5Overlay rounded origin-top-left flex flex-col',
          hasFixedDimensions && 'w-[1366px] h-[768px] absolute',
          isClickable || 'cursor-not-allowed z-10',
        )}
        style={{ left: `${left}px`, scale: String(scale), top: `${top}px` }}
      />
      <div
        className={c(
          'slideviewerCK5Slide rounded origin-top-left flex flex-col',
          hasFixedDimensions && 'w-[1366px] h-[768px] absolute',
          hasFixedDimensions && !isTransparent && !isThumbnail && 'bg-white',
        )}
        style={{ left: `${left}px`, scale: String(scale), top: `${top}px` }}
      >
        <div
          key={`slide-${currentSlide?.id}`}
          ref={contentElementRef}
          className={c(
            'ck5Slide rounded flex-1 overflow-x-hidden',
            isClickable ? 'overflow-y-auto' : 'overflow-y-hidden',
          )}
        >
          {children}
        </div>
        {question && hasFixedDimensions && (
          <div className="flex-none text-black px-12 py-4 rounded bg-white relative max-h-[75%]">
            <ScrollIndicator contentElementRef={contentElementRef} />
            <SlideViewerQuestion
              key={`question-${currentSlide.id}`}
              canSubmit
              question={question}
            />
          </div>
        )}
      </div>
    </>
  );
}
