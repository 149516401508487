import { useLayoutEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { boolean, object, string } from 'yup';

import { hideDialog } from '@ftrprf/editor';
import {
  allLinkStyles,
  CheckBox,
  Dialog,
  DialogContent,
  DialogHeader,
  Label,
  SLIDEVIEWER_PREFIX,
} from '@ftrprf/tailwind-components';
import useFormatMessage from '../../hooks/useFormatMessage';

import FormikInput from '../Form/FormikInput';
import FormikUrl from '../Form/FormikUrl';

import { ExerciseDialog } from './ExercisePlugin';
import { TYPE_SLIDE_PREFIX } from '../../pages/ContentEditor/partials/Ck5Editor/plugins/LinkPlugin';
import { addNewLinkElement } from './functions/addNewLinkElement';
import { editLinkElement } from './functions/editLinkElement';
import { LinkActions } from './LinkPlugin/LinkActions';
import { LinkPreview } from './LinkPlugin/LinkPreview';
import { getSlideAndStudioId } from '../../utils/functions/getSlideAndStudioId';
import { styledLinks } from '../../pages/ContentEditor/partials/Ck5Editor/plugins/helpers/styledLinks';

// NB: It's not a regular CKEDITOR plugin
// How it works:
// 1. ftrprf-ckeditor (forked repo of CKEDITOR) throws a custom showLinkDialogEvent
//    during the original onShow event of link dialog
// 2. This file intercepts showLinkDialogEvent and completely replaces the modal and its logic
export function LinkPlugin({ editorAPI }) {
  const t = useFormatMessage();
  const [isDialogShown, setIsDialogShown] = useState(false);
  const [selectedStyle, setSelectedStyle] = useState();
  const [initialLinkElement, setInitialLinkElement] = useState();
  const [isWarning, setIsWarning] = useState(false);

  const onSubmit = (values) => {
    const { url } = values;
    const isHackRoomLesson = url.includes('hackroom');
    const isSlideLink =
      url.startsWith(TYPE_SLIDE_PREFIX) || selectedStyle === styledLinks.SLIDE;
    let slideId;
    let studioId;

    if (isSlideLink) {
      const ids = getSlideAndStudioId(isHackRoomLesson, url);
      slideId = ids.slideId;
      studioId = ids.studioId;
    }

    if (!initialLinkElement) {
      addNewLinkElement({
        ...values,
        editorAPI,
        isHackRoomLesson,
        selectedStyle,
        slideId,
        studioId,
      });
    } else {
      editLinkElement({
        ...values,
        initialLinkElement,
        isHackRoomLesson,
        selectedStyle,
        slideId,
        studioId,
      });
    }
    editorAPI.fire('change'); // send a backend call to update the content
    setIsDialogShown(false);
  };

  const setSelectedStyleEnhanced = (style) => {
    setSelectedStyle((prevStyle) => (prevStyle === style ? null : style));
  };

  useLayoutEffect(() => {
    function openLinkDialog(event) {
      // process the event only if it was fired from the necessary editor
      // (currently showLinkDialogEvent is dispatched from each instance of ckeditor)
      if (editorAPI.name === event.detail.editorName) {
        setInitialLinkElement(event.detail.linkElement);
        setSelectedStyle(
          Object.values(allLinkStyles).find((className) =>
            event.detail.linkElement?.hasClass(
              `${SLIDEVIEWER_PREFIX}${className}`,
            ),
          ),
        );
        hideDialog();
        setIsDialogShown(true);
      }
    }

    window.addEventListener('showLinkDialogEvent', openLinkDialog);
    return () => {
      window.removeEventListener('showLinkDialogEvent', openLinkDialog);
    };
  }, [editorAPI]);

  const generateTitle = () => {
    if (selectedStyle === allLinkStyles.EXERCISE) {
      return t('link-plugin.url.modify-exercise');
    }

    return initialLinkElement
      ? t('link-plugin.url.modify-url')
      : t('link-plugin.url.add-url');
  };

  const isExercise = initialLinkElement?.hasClass('SlideViewer__ExerciseLink');

  const validateUrl = async (value) => {
    if (value.startsWith('slide://') || value.includes('localhost')) {
      return null;
    }
    const urlSchema = object({
      url: string().url('validation.url').required('validation.required'),
    });
    return urlSchema
      .validate({ url: value })
      .then(() => null)
      .catch((error) => {
        if (error.errors && error.errors.length > 0) {
          return error.errors[0];
        }
        return null;
      });
  };

  // initial value for the url input
  const initialUrlValue = () => {
    const href = initialLinkElement?.getAttribute('href');
    const slideId = initialLinkElement?.getAttribute('data-target-slide-id');
    if (slideId && !href) {
      const url = initialLinkElement?.getAttribute('data-url');
      if (url?.startsWith(TYPE_SLIDE_PREFIX)) {
        return url;
      }
      return `${TYPE_SLIDE_PREFIX}${slideId}`;
    }
    return href || initialLinkElement?.getAttribute('download-url');
  };

  if (isExercise) {
    // initialLinkElement is never null in this case
    return (
      isDialogShown && (
        <ExerciseDialog
          displayText={initialLinkElement?.getHtml()}
          onDismiss={() => setIsDialogShown(false)}
          onSubmit={({ displayText, exerciseVersionId, linkId }) => {
            initialLinkElement.setAttribute('versionid', exerciseVersionId);
            initialLinkElement.setAttribute('data-studio-link-id', linkId);
            initialLinkElement.setHtml(displayText);
          }}
          versionId={initialLinkElement?.getAttribute('versionid')}
        />
      )
    );
  }

  return (
    <Dialog
      aria-label={t('link-plugin.url.dialog')}
      isOpen={isDialogShown}
      onDismiss={() => {
        setIsDialogShown(false);
      }}
    >
      <Formik
        initialValues={{
          displayText:
            initialLinkElement?.getHtml() ||
            editorAPI.getSelection()?.getSelectedText(),
          fileName:
            initialLinkElement?.getAttribute('download-file-name') || '',
          isBlank:
            !initialLinkElement ||
            initialLinkElement.getAttribute('target') === '_blank',
          isDownload: !!initialLinkElement?.getAttribute('download-url'),
          url: initialUrlValue(),
        }}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validationSchema={object({
          displayText: string().optional(),
          ...(selectedStyle === allLinkStyles.EXERCISE || {
            fileName: string().nullable(true).optional(),
            isBlank: boolean().required(),
            isDownload: boolean().required(),
          }),
        })}
      >
        {({ isSubmitting, isValid, values }) => (
          <Form>
            <DialogHeader>{generateTitle()}</DialogHeader>
            <DialogContent>
              {selectedStyle === allLinkStyles.EXERCISE ? (
                <FormikInput
                  label={t('link-plugin.display-text')}
                  name="displayText"
                  type="text"
                />
              ) : (
                <>
                  <Field name="url" type="url">
                    {({ form: { setFieldValue } }) => (
                      <FormikUrl
                        label={t('global.URL')}
                        name="url"
                        onChange={(e) => {
                          setFieldValue('url', e.target.value);
                          if (
                            e.target.value &&
                            e.target.value.startsWith('slide://')
                          ) {
                            setSelectedStyle(allLinkStyles.SLIDE);
                            setIsWarning(true);
                          } else {
                            setIsWarning(false);
                          }
                        }}
                        validate={validateUrl}
                      />
                    )}
                  </Field>
                  {isWarning && (
                    <div className="text-sm text-orange-600 mt-2">
                      <p>{t('link-plugin.url.warning')}</p>
                      <ul className="list-disc list-inside">
                        <li>{t('link-plugin.url.warning.1')}</li>
                        <li>{t('link-plugin.url.warning.2')}</li>
                      </ul>
                    </div>
                  )}
                  <FormikInput
                    label={t('link-plugin.display-text')}
                    name="displayText"
                    type="text"
                  />
                  {values.isDownload && (
                    <FormikInput
                      label={t('link-plugin.file_name')}
                      name="fileName"
                      type="text"
                    />
                  )}
                  <Field name="isDownload" type="checkbox">
                    {({ field: { value }, form: { setFieldValue } }) => (
                      <CheckBox
                        checked={value}
                        label={t('link-plugin.is_download')}
                        onChange={(e) => {
                          setFieldValue('isDownload', e);
                          if (e) {
                            setSelectedStyle(allLinkStyles.DOWNLOAD);
                            setFieldValue('isBlank', false);
                          }
                        }}
                      />
                    )}
                  </Field>
                  {!values.isDownload && (
                    <>
                      <div className="h-2" />
                      <Field name="isBlank" type="checkbox">
                        {({ field: { value }, form: { setFieldValue } }) => (
                          <CheckBox
                            checked={value}
                            label={t('link-plugin.open_new_tab')}
                            onChange={(e) => setFieldValue('isBlank', e)}
                          />
                        )}
                      </Field>
                    </>
                  )}
                  <Label className="mt-4">
                    {t('link-plugin.url.link-style.label')}{' '}
                    <span className="text-xs text-gray-600">
                      {t('global.not_required')}
                    </span>
                  </Label>
                  <LinkPreview
                    selectedStyle={selectedStyle}
                    setSelectedStyleEnhanced={setSelectedStyleEnhanced}
                  />
                </>
              )}
            </DialogContent>
            <LinkActions
              generateTitle={generateTitle}
              isSubmitting={isSubmitting}
              isValid={isValid}
              setIsDialogShown={setIsDialogShown}
              setIsWarning={setIsWarning}
            />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
