import {
  SLIDEVIEWER_PREFIX,
  STYLED_BUTTON_CLASS,
} from '@ftrprf/tailwind-components';
import c from '../../../utils/functions/c';

export function addNewLinkElement({
  displayText,
  editorAPI,
  fileName,
  isBlank,
  isDownload,
  isHackRoomLesson,
  selectedStyle,
  slideId,
  studioId,
  url,
}) {
  editorAPI.insertHtml(
    `<a
            ${!isDownload && !slideId && `href="${url}"`}
            class="${c(
              selectedStyle && STYLED_BUTTON_CLASS,
              selectedStyle && `${SLIDEVIEWER_PREFIX}${selectedStyle}`,
            )}"
            ${slideId && `data-target-slide-id=${slideId}`}
            ${studioId && `data-target-studio-id=${studioId}`}
            ${isBlank && 'target="_blank" rel="noreferrer"'}
            ${isDownload && `download-file-name="${fileName}"`}
            ${isDownload && `download-url="${url}"`}
            ${isHackRoomLesson && 'data-target-hackroom-lesson=true'}
            data-url="${url}"
          >
            ${displayText || url}
          </a>`,
  );
}
