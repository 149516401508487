export const allLinkStyles = {
  DOWNLOAD: 'DownloadLink',
  EXERCISE: 'ExerciseLink',
  EXTERNAL: 'ExternalLink',
  MOVIE: 'VideoLink',
  OTHER: 'OtherLink',
  SLIDE: 'SlideLink',
  STEAMS: 'SteamsQR',
};

export const EXERCISE_LINKS = {
  NEW: 'SlideViewer__ExerciseLink',
  OLD: 'EXERCISE_SCRATCH',
};

export const link = {
  DOWNLOAD: 'DownloadLink',
  EXERCISE: 'ExerciseLink',
  EXTERNAL: 'ExternalLink',
  MOVIE: 'VideoLink',
  OTHER: 'OtherLink',
  SLIDE: 'SlideLink',
  STEAMS: 'SteamsQR',
};
