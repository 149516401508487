/* eslint-disable react/jsx-props-no-spreading */
import { useContext, useState } from 'react';
import { Resizable } from 're-resizable';

import { ActionStyledAsButton, Loader } from '@ftrprf/tailwind-components';

import useFormatMessage from '../../../../hooks/useFormatMessage';
import useSlide from '../../hooks/useSlide';

import { SIDE_BARS, SideBarContext, sideBars } from './SideBarContextProvider';
import SlideSideBar from './SlideSideBar';
import { stopEventBubblingOnArrowUpOrDown } from '../../utils/stopEventBubblingOnArrowUpOrDown';
import TeacherInfoSideBar from './TeacherInfoSideBar';
import HintsSideBar from './HintsSideBar/HintsSideBar';
import { sidebarTypes } from './SidebarTypes';

export function SideBar({ disabled, enable, slideId, studioId }) {
  const { createHint, slide } = useSlide(slideId);
  const [isCreatingHint, setIsCreatingHint] = useState(false);
  const { currentSideBar, setSideBar } = useContext(SideBarContext);
  const SideBarComponent = currentSideBar?.type
    ? sideBars[currentSideBar.type]
    : SlideSideBar;

  const isPermanentSideBar =
    currentSideBar?.type === sidebarTypes.hints ||
    currentSideBar?.type === sidebarTypes.teacherInfo;

  const t = useFormatMessage();

  if (!slide) {
    return null;
  }

  const hasHints = slide.hints.length > 0;

  return (
    // this is no problem as it's just to deactivate an event listener
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className="flex flex-col flex-shrink-0 overflow-hidden sidebar_ck5"
      onKeyDown={stopEventBubblingOnArrowUpOrDown}
      tabIndex={-1}
    >
      <div className="h-16 flex items-center justify-end p-4 border-b border-gray-300 bg-gray-50">
        <div className="h-6 w-px bg-gray-300" />
        <div className="px-4">
          <ActionStyledAsButton
            disabled={disabled}
            onClick={() =>
              setSideBar({ type: SIDE_BARS.TEACHER_INFO_SIDEBAR, id: slideId })
            }
            secondary
          >
            {t('content-editor.buttons.manual')}
          </ActionStyledAsButton>
        </div>
        <div className="h-6 w-px bg-gray-300" />
        <div className="pl-4">
          {hasHints ? (
            <ActionStyledAsButton
              badge={slide.hints.length}
              onClick={() =>
                setSideBar({ type: SIDE_BARS.HINTS_SIDEBAR, id: slideId })
              }
              secondary
            >
              {t('content-editor.buttons.hints')}
            </ActionStyledAsButton>
          ) : (
            <ActionStyledAsButton
              disabled={disabled || isCreatingHint}
              onClick={() => {
                setIsCreatingHint(true);
                createHint().then(() => {
                  setSideBar({ type: SIDE_BARS.HINTS_SIDEBAR, id: slideId });
                  setIsCreatingHint(false);
                });
              }}
              secondary
            >
              {t('content-editor.buttons.add_hint')}{' '}
              {isCreatingHint && (
                <span className="inline-block w-3 h-3">
                  <Loader />
                </span>
              )}
            </ActionStyledAsButton>
          )}
        </div>
      </div>
      <Resizable
        className="overflow-y-auto overflow-x-hidden flex-shrink-0 flex-grow flex-basis-0"
        defaultSize={{ width: '350' }}
        enable={enable}
        maxWidth="500"
        minWidth="350"
      >
        <div className="flex border-l border-gray-300 h-full">
          <TeacherInfoSideBar
            disabled={disabled}
            hide={currentSideBar?.type !== sidebarTypes.teacherInfo}
            slideId={slideId}
            {...currentSideBar}
          />
          <HintsSideBar
            disabled={disabled}
            hide={currentSideBar?.type !== sidebarTypes.hints}
            id={slideId}
            {...currentSideBar}
          />
          {!isPermanentSideBar && (
            <SideBarComponent
              disabled={disabled}
              slideId={slideId}
              studioId={studioId}
              {...currentSideBar}
            />
          )}
        </div>
      </Resizable>
    </div>
  );
}
