import { Link as LinkTo } from 'react-router-dom';

export function InternalLink({
  children,
  className,
  generateCurrentLessonPath,
  generateLessonPath,
  isHackRoomLesson,
  slideId,
  studioId,
}) {
  let url;

  if (studioId) {
    url = generateLessonPath
      ? generateLessonPath({ isHackRoomLesson, slideId, studioId })
      : '#';
  } else {
    url = generateCurrentLessonPath
      ? generateCurrentLessonPath({ slideId })
      : '#';
  }

  return (
    <LinkTo className={className} to={url}>
      {children}
    </LinkTo>
  );
}
