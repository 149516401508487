import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { BroadcastChannel } from 'broadcast-channel';
import calculateProgress from '../../utils/calculateProgress';
import { multiScreenModeActions } from './MultiScreenMode/multiScreenModeActions';
import { viewModes } from '../../utils/constants/viewModes';

export const SlideViewerContext = createContext({});
const multiScreenModeBroadcastChannel = new BroadcastChannel('multiScreen');
const presentatorControlsBroadcastChannel = new BroadcastChannel(
  'multiScreenControls',
);

export default function SlideViewerContextProvider({
  canOverrideAnswers = false,
  children,
  generateCurrentLessonPath = () => {},
  generateLessonPath = () => {},
  generateScratchExercisePath = () => {},
  hasMultiScreenMode = false,
  isCk5,
  isCodefever = false,
  isSubmittingAnswers = false,
  lesson,
  lessonSessionId = '',
  onChangeSlide = () => {},
  onChangeViewMode = () => {},
  onSubmitQuestionAnswers = () => {},
  preview = false,
  refetchAnswers = () => {},
  selectedPeriodIsNotActivePeriod = false,
  sendPageNumber = null,
  setViewMode,
  slideId,
  slideIndex = 0,
  slides = [],
  studioId,
  submittedQuestionAnswers = [],
  useTeacherCreateContentFeedback,
  user,
  userDoesNotHaveSteams = true,
  userGroupInfo,
  viewMode,
}) {
  const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [currentSlideId, setCurrentSlideId] = useState(slideId);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(slideIndex || 0);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [submitAnswer, setSubmitAnswer] = useState();
  const [coachCodiIsOpen, setCoachCodiIsOpen] = useState(true);
  // because steams has its own virtual moderator we cannot show ours if they have steams.
  const [hasAssistant, setHasAssistant] = useState(userDoesNotHaveSteams);

  useEffect(() => {
    const thereAreSlidesWithHintsOrMotivation = slides.some(
      (slide) => slide?.motivation || Boolean(slide?.hints?.length),
    );

    setHasAssistant(
      thereAreSlidesWithHintsOrMotivation && userDoesNotHaveSteams,
    );
  }, [slides, setHasAssistant, userDoesNotHaveSteams]);

  useEffect(() => {
    if (currentSlideId !== slideId) {
      setCurrentSlideId(slideId);
    }
  }, [currentSlideId, slideId]);

  const progress =
    slides.length <= 1
      ? 100
      : calculateProgress(slides.length - 1, currentSlideIndex);

  const findCurrentSlide = useCallback(() => {
    if (
      slides[currentSlideIndex] &&
      slides[currentSlideIndex].id === currentSlideId
    ) {
      return slides[currentSlideIndex];
    }
    if (!currentSlideId) {
      if (slides[currentSlideIndex]) return slides[currentSlideIndex];
      if (slideIndex && slides[slideIndex]) return slides[slideIndex];

      return slides[0];
    }

    return slides.find((item) => item.id === currentSlideId);
  }, [currentSlideId, currentSlideIndex, slideIndex, slides]);

  const [currentSlide, setCurrentSlide] = useState(findCurrentSlide());
  const [fontSizeIndex, setFontSizeIndex] = useState(0);

  useEffect(() => {
    if (user.isStudent) {
      const question = currentSlide?.question;

      const submittedQuestionAnswer =
        question &&
        submittedQuestionAnswers?.find(
          (submittedQuestion) => submittedQuestion.questionId === question.id,
        );

      const hasSubmittedQuestionAnswer = !!submittedQuestionAnswer;
      setCanEdit(
        user.isStudent && (!hasSubmittedQuestionAnswer || canOverrideAnswers),
      );
    }
    if (user.isTeacher) {
      setCanEdit(true);
    }
  }, [
    canOverrideAnswers,
    currentSlide,
    submittedQuestionAnswers,
    user.isStudent,
    user.isTeacher,
  ]);

  const setSlide = useCallback(
    (index) => {
      if (!slides) {
        return;
      }
      const newSlide = slides[index];
      void multiScreenModeBroadcastChannel.postMessage({
        currentSlide: { ...newSlide, index },
        length: slides.length,
        nextSlide: slides[index + 1],
        sessionId: lessonSessionId,
        studioId,
        viewMode,
      });

      setCurrentSlideIndex(index);
      setCurrentSlide(newSlide);
      setCurrentSlideId(newSlide?.id);
      onChangeSlide(newSlide?.id);
    },
    [lessonSessionId, onChangeSlide, slides, studioId, viewMode],
  );

  useEffect(() => {
    setCurrentSlide(findCurrentSlide());
    setCurrentSlideIndex(slides.indexOf(currentSlide));
  }, [currentSlide, findCurrentSlide, slides]);

  useEffect(() => {
    if (currentSlide && !!sendPageNumber) {
      sendPageNumber(currentSlide.sequence);
    }
  }, [currentSlide, sendPageNumber]);

  useEffect(() => {
    presentatorControlsBroadcastChannel.onmessage = (message) => {
      if (message === multiScreenModeActions.initialize) {
        setSlide(currentSlideIndex);
        return;
      }

      if (
        message === multiScreenModeActions.previousSlide &&
        currentSlideIndex > 0
      ) {
        setSlide((currentSlideIndex ?? 0) - 1);
        return;
      }

      if (
        message === multiScreenModeActions.nextSlide &&
        currentSlideIndex < slides.length - 1
      ) {
        setSlide((currentSlideIndex ?? 0) + 1);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlideIndex]);

  const context = useMemo(
    () => ({
      canEdit,
      canOverrideAnswers,
      coachCodiIsOpen,
      currentSlide,
      currentSlideId,
      currentSlideIndex,
      fontSizeIndex,
      generateCurrentLessonPath,
      generateLessonPath,
      generateScratchExercisePath,
      hasAssistant,
      hasMultiScreenMode,
      isCk5,
      isCodefever,
      isFeedbackDialogOpen,
      isSubmittingAnswers,
      lesson,
      lessonSessionId,
      onChangeViewMode,
      onSubmitQuestionAnswers,
      preview,
      progress,
      refetchAnswers,
      selectedPeriodIsNotActivePeriod,
      setCoachCodiIsOpen,
      setCurrentSlide,
      setCurrentSlideId,
      setCurrentSlideIndex,
      setFontSizeIndex,
      setIsFeedbackDialogOpen,
      setShowConfirmationDialog,
      setSlide,
      setSubmitAnswer,
      setViewMode,
      showConfirmationDialog,
      slideId,
      slides,
      studioId,
      submitAnswer,
      submittedQuestionAnswers,
      useTeacherCreateContentFeedback,
      user,
      userGroupInfo,
      viewMode,
    }),
    [
      canEdit,
      canOverrideAnswers,
      coachCodiIsOpen,
      currentSlide,
      currentSlideId,
      currentSlideIndex,
      fontSizeIndex,
      generateCurrentLessonPath,
      generateLessonPath,
      generateScratchExercisePath,
      hasAssistant,
      hasMultiScreenMode,
      isCk5,
      isCodefever,
      isFeedbackDialogOpen,
      isSubmittingAnswers,
      lesson,
      lessonSessionId,
      onChangeViewMode, // todo: figure out where this is used, seems to be nowhere.  Included it above now to shut
      // up eslint
      onSubmitQuestionAnswers,
      preview,
      progress,
      refetchAnswers,
      selectedPeriodIsNotActivePeriod,
      setCoachCodiIsOpen,
      setCurrentSlide,
      setCurrentSlideId,
      setCurrentSlideIndex,
      setFontSizeIndex,
      setIsFeedbackDialogOpen,
      setShowConfirmationDialog,
      setSlide,
      setSubmitAnswer,
      setViewMode,
      showConfirmationDialog,
      slideId,
      slides,
      studioId,
      submitAnswer,
      submittedQuestionAnswers,
      useTeacherCreateContentFeedback,
      user,
      userGroupInfo,
      viewMode,
    ],
  );

  useEffect(() => {
    const handle = (e) => {
      if (!isFeedbackDialogOpen) {
        if (e.key === 'ArrowLeft' && currentSlideIndex > 0) {
          setSlide(currentSlideIndex - 1);
        }

        if (e.key === 'ArrowRight' && currentSlideIndex < slides.length - 1) {
          setSlide(currentSlideIndex + 1);
        }
      }
    };
    window.addEventListener('keyup', handle);
    return () => window.removeEventListener('keyup', handle);
  }, [
    currentSlideIndex,
    fontSizeIndex,
    isFeedbackDialogOpen,
    setSlide,
    slides,
  ]);

  useEffect(() => {
    setFontSizeIndex(0);
    if (viewMode === viewModes.PROJECTION) {
      setCoachCodiIsOpen(false);
    }
  }, [viewMode]);

  return (
    <SlideViewerContext.Provider value={context}>
      {children}
    </SlideViewerContext.Provider>
  );
}
