import { useCallback, useEffect } from 'react';
import { ExercisePlugin } from './ExercisePlugin';
// eslint-disable-next-line import/no-cycle
import { ModalPlugin } from './ModalPlugin';
import { LinkPlugin } from './LinkPlugin';
import { SourcePlugin } from './SourcePlugin';

import { plugins } from './helpers/plugins';
import { determineType } from './helpers/determineType';
import { styledLinks } from './helpers/styledLinks';
import { generatePrefixedType } from './helpers/generatePrefixedType';
import { HtmlInsertPlugin } from './HtmlInsertPlugin';
import { ImagePlugin } from './ImagePlugin';

export function Plugins({ editorContext, isInSideBar = false }) {
  const {
    currentPlugin,
    editor,
    id,
    insertContent,
    isEditorReady,
    selectedElement,
    setCurrentPlugin,
    setSelectedElement,
    slide,
    updateContent,
  } = editorContext;

  useEffect(() => {
    // listen for click in editor
    if (isEditorReady && editor && editor.editing) {
      const { view } = editor.editing;
      const viewDocument = view.document;
      editor.listenTo(viewDocument, 'click', (evt, data) => {
        const element = data.domTarget;
        if (element.classList.contains('SlideViewer__ExerciseLink')) {
          setSelectedElement({
            html: element.outerHTML,
            id: Number(element.attributes['data-exercise-id'].value),
            label: element.innerHTML,
            studioId: Number(element.attributes['data-studio-link-id'].value),
            versionId: Number(element.attributes['data-version-id'].value),
          });

          setCurrentPlugin(plugins.EXERCISE);
          return;
        }

        if (editor.removeBlockStyle && element && element.nodeName !== 'DIV') {
          if (element.parentElement.nodeName === 'P') {
            const oldParentHtml = `${element.parentElement.outerHTML}`;
            element.parentElement.classList = [];
            element.parentElement.style = '';
            updateContent(element.parentElement.outerHTML, oldParentHtml);
          }
          const oldHtml = `${element.outerHTML}`;
          element.classList = [];
          element.style = '';
          updateContent(element.outerHTML, oldHtml);
        }

        const isLink =
          element.classList.contains(styledLinks.DEFAULT) ||
          Object.values(styledLinks).find((linkType) =>
            element.classList.contains(generatePrefixedType(linkType)),
          );
        if (Boolean(isLink)) {
          let url;
          if (element.getAttribute('data-target-slide-id')) {
            url = element.getAttribute('data-url');
          } else if (element.getAttribute('download-url')) {
            url = element.getAttribute('download-url');
          } else {
            url =
              element.getAttribute('data-href') ?? element.getAttribute('href');
          }

          const label = element.innerText.trim();

          setSelectedElement({
            fileName: element.getAttribute('download-file-name'),
            html: element.outerHTML,
            isExternal: Boolean(element.getAttribute('target')),
            label: label === url ? '' : label,
            type: determineType(Array.from(element.classList)),
            url,
          });

          setCurrentPlugin(plugins.LINK);
          return;
        }

        if (element.classList.contains('SlideViewer__ModalLink')) {
          setSelectedElement({
            buttonText: element.innerHTML,
            html: element.outerHTML,
            modalContent: element.attributes['data-modal-content'].value,
            modalSize: element.attributes['data-modal-size'].value,
            modalTitle: element.attributes['data-modal-title'].value,
          });
          setCurrentPlugin(plugins.MODAL);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    editor,
    isEditorReady,
    setCurrentPlugin,
    setSelectedElement,
    updateContent,
  ]);

  const onDismiss = useCallback(() => {
    setCurrentPlugin('');
    setSelectedElement();
  }, [setCurrentPlugin, setSelectedElement]);

  switch (currentPlugin) {
    case plugins.MODAL:
      return (
        <ModalPlugin
          id={id}
          isInSideBar={isInSideBar}
          onDismiss={onDismiss}
          save={!!selectedElement ? updateContent : insertContent}
          selectedElement={selectedElement}
          slide={slide}
        />
      );
    case plugins.LINK:
      return (
        <LinkPlugin
          isNew={!selectedElement}
          onDismiss={onDismiss}
          save={!!selectedElement ? updateContent : insertContent}
          selectedLink={selectedElement}
        />
      );
    case plugins.EXERCISE:
      return (
        <ExercisePlugin
          isNew={!selectedElement}
          onDismiss={onDismiss}
          save={!!selectedElement ? updateContent : insertContent}
          selectedExercise={selectedElement}
        />
      );
    case plugins.SOURCE:
      return (
        <SourcePlugin editorContext={editorContext} onDismiss={onDismiss} />
      );
    case plugins.HTML_INSERT:
      return <HtmlInsertPlugin insert={insertContent} onDismiss={onDismiss} />;
    case plugins.IMAGE:
      return <ImagePlugin insert={insertContent} onDismiss={onDismiss} />;
    default:
      return null;
  }
}
