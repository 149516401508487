import { STYLED_BUTTON_CLASS } from './Link';
import { EXERCISE_LINKS } from '../utils/constants/link';

export function ExerciseLink({
  children,
  dataStudioLinkId,
  dataVersionId,
  generateScratchExercisePath,
  href,
  url,
  versionId,
}) {
  return (
    <a
      className={`${STYLED_BUTTON_CLASS} ${EXERCISE_LINKS.NEW}`}
      // there might be a possibility where the exercises contain other hrefs
      // to external exercises for example. If this is the case, just pass href
      href={
        !!dataStudioLinkId &&
        !!(versionId || dataVersionId) &&
        generateScratchExercisePath
          ? generateScratchExercisePath({
              lessonContentId: dataStudioLinkId,
              versionId: versionId || dataVersionId,
            })
          : href || url
      }
      rel="noreferrer noopener"
      target="_blank"
    >
      {children}
    </a>
  );
}
