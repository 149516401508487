import {
  SLIDEVIEWER_PREFIX,
  STYLED_BUTTON_CLASS,
} from '@ftrprf/tailwind-components';
import { availableLinkStyles } from '../../../utils/constants/availableLinkStyles';

export function editLinkElement({
  displayText,
  fileName,
  initialLinkElement,
  isBlank,
  isDownload,
  isHackRoomLesson,
  selectedStyle,
  slideId,
  studioId,
  url,
}) {
  initialLinkElement.removeAttribute('data-cke-saved-href');
  initialLinkElement.removeAttribute('data-url');
  initialLinkElement.setHtml(displayText || url);

  if (!!url && !slideId) {
    initialLinkElement.setAttribute('href', url);
  }

  if (slideId) {
    initialLinkElement.setAttribute('data-target-slide-id', slideId);
    initialLinkElement.removeAttribute('href');
    initialLinkElement.setAttribute('data-url', url);
  }

  if (studioId) {
    initialLinkElement.setAttribute('data-target-studio-id', studioId);
  }

  if (isHackRoomLesson) {
    initialLinkElement.setAttribute('data-target-hackroom-lesson', 'true');
  } else {
    initialLinkElement.removeAttribute('data-target-hackroom-lesson');
  }

  if (isBlank) {
    initialLinkElement.setAttribute('target', '_blank');
    initialLinkElement.setAttribute('rel', 'noreferrer');
  } else {
    initialLinkElement.removeAttribute('target');
  }

  if (isDownload) {
    initialLinkElement.removeAttribute('href');
    initialLinkElement.removeAttribute('target');
    initialLinkElement.setAttribute('download-file-name', fileName);
    initialLinkElement.setAttribute('download-url', url);
  } else {
    initialLinkElement.removeAttribute('download-file-name');
    initialLinkElement.removeAttribute('download-url');
  }

  Object.values(availableLinkStyles).map((style) =>
    initialLinkElement.removeClass(`${SLIDEVIEWER_PREFIX}${style}`),
  );

  if (selectedStyle) {
    initialLinkElement.addClass(`${SLIDEVIEWER_PREFIX}${selectedStyle}`);
    if (!initialLinkElement.hasClass(STYLED_BUTTON_CLASS)) {
      initialLinkElement.addClass(`${STYLED_BUTTON_CLASS}`);
    }
  } else {
    initialLinkElement.removeClass(STYLED_BUTTON_CLASS);
  }
}
