import { useContext } from 'react';

import { SlideViewerContext } from '../SlideViewerContextProvider';
// eslint-disable-next-line import/no-cycle
import ModalButton from './ModalButton';
import { MultiScreenModeContext } from '../MultiScreenMode/MultiScreenModeContextProvider';
// eslint-disable-next-line import/no-cycle
import { ExerciseLink } from './ExerciseLink';
import { InternalLink } from './InternalLink';
// eslint-disable-next-line import/no-cycle
import { ExternalLink } from './ExternalLink';
import { EXERCISE_LINKS } from '../utils/constants/link';
import { downloadResource } from '../utils/functions/downloadResource';

export const SLIDEVIEWER_PREFIX = 'SlideViewer__';
export const STYLED_BUTTON_CLASS = `${SLIDEVIEWER_PREFIX}StyledButton`;
export const LESSON_ID = 'LESSON_ID';

/**
 * The data-attributes will appear automagically, if and only if they were set on the link & the backend doesn't
 * filter them out (aka: if they're in their whitelist).
 */
export default function Link({
  children,
  className,
  'data-href': url,
  'data-studio-link-id': dataStudioLinkId,
  'data-target-hackroom-lesson': isHackRoomLesson = false,
  'data-target-slide-id': slideId,
  'data-target-studio-id': studioId,
  'data-version-id': dataVersionId,
  'download-file-name': downloadFileName,
  'download-url': downloadUrl,
  href,
  target,
  versionid: versionId,
  ...rest
}) {
  const {
    generateCurrentLessonPath: slideviewerGenerateCurrentLessonPath,
    generateLessonPath: slideViewerGenerateLessonPath,
    generateScratchExercisePath: slideviewerGenerateScratchExercisePath,
    lesson,
  } = useContext(SlideViewerContext);

  const {
    generateCurrentLessonPath: multiScreenGenerateCurrentLessonPath,
    generateLessonPath: multiscreenGenerateLessonPath,
    generateScratchExercisePath: multiScreenGenerateScratchExercisePath,
  } = useContext(MultiScreenModeContext);

  const isModal = className?.includes('SlideViewer__ModalLink');

  if (isModal) {
    return (
      <ModalButton
        className={className}
        label={rest['data-label']}
        modalcontent={rest['data-modal-content']}
        modalsize={rest['data-modal-size']}
        modaltitle={rest['data-modal-title']}
      >
        {children}
      </ModalButton>
    );
  }

  const isExercise =
    className?.includes(EXERCISE_LINKS.OLD) ||
    className?.includes(EXERCISE_LINKS.NEW);

  if (isExercise) {
    return (
      <ExerciseLink
        dataStudioLinkId={dataStudioLinkId}
        dataVersionId={dataVersionId}
        generateScratchExercisePath={
          slideviewerGenerateScratchExercisePath ??
          multiScreenGenerateScratchExercisePath
        }
        href={href}
        url={url}
        versionId={versionId}
      >
        {children}
      </ExerciseLink>
    );
  }

  const generateCurrentLessonPath =
    slideviewerGenerateCurrentLessonPath ??
    multiScreenGenerateCurrentLessonPath;
  const generateLessonPath =
    slideViewerGenerateLessonPath ?? multiscreenGenerateLessonPath;

  if (slideId && target !== '_blank') {
    return (
      <InternalLink
        className={className}
        generateCurrentLessonPath={generateCurrentLessonPath}
        generateLessonPath={generateLessonPath}
        isHackRoomLesson={isHackRoomLesson}
        slideId={slideId}
        studioId={studioId}
      >
        {children}
      </InternalLink>
    );
  }

  if (downloadUrl) {
    return (
      <button
        className={className}
        onClick={() => downloadResource(downloadUrl, downloadFileName)}
        type="button"
      >
        {children}
      </button>
    );
  }

  return (
    <ExternalLink
      className={className}
      generateCurrentLessonPath={generateCurrentLessonPath}
      generateLessonPath={generateLessonPath}
      href={href}
      isHackRoomLesson={isHackRoomLesson}
      lesson={lesson}
      slideId={slideId}
      studioId={studioId}
      target={target}
      url={url}
    >
      {children}
    </ExternalLink>
  );
}
