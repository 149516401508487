import { forceDownload } from './forceDownload';

export function downloadResource(url, filename) {
  let modifiedFilename = filename.slice();

  if (!filename) {
    modifiedFilename = url.split('\\').pop().split('/').pop();
  } else if (filename.split('.').length < 2) {
    modifiedFilename = `${filename}.${url.split('.').pop()}`;
  }

  fetch(url, {
    headers: new Headers({
      Origin: location.origin,
    }),
  })
    .then((response) => response.blob())
    .then((blob) => {
      const blobUrl = window.URL.createObjectURL(blob);
      forceDownload(blobUrl, modifiedFilename);
    });
}
