import { LESSON_ID } from './Link';

export function ExternalLink({
  children,
  className,
  generateCurrentLessonPath,
  generateLessonPath,
  href,
  isHackRoomLesson = false,
  lesson,
  slideId,
  studioId,
  target,
  url,
}) {
  let modifiedHref = href?.slice();
  if (modifiedHref?.includes(LESSON_ID)) {
    modifiedHref = modifiedHref.replace(LESSON_ID, lesson?.id);
  }

  if (slideId && !studioId) {
    modifiedHref = generateCurrentLessonPath
      ? generateCurrentLessonPath({ slideId })
      : '#';
  }

  if (slideId && studioId) {
    modifiedHref = generateLessonPath
      ? generateLessonPath({ isHackRoomLesson, slideId, studioId })
      : '#';
  }

  return (
    <a
      className={className}
      href={modifiedHref || url}
      rel="noreferrer noopener"
      target={target}
    >
      {children}
    </a>
  );
}
