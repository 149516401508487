import './editor.css';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import { SLIDE_BACKGROUNDS } from '@ftrprf/tailwind-components';
import { useContext, useEffect, useState } from 'react';

import c from 'utils/functions/c';
import useFormatMessage from 'hooks/useFormatMessage';
import { plugins } from './plugins/helpers/plugins';
import { contentTemplates } from './plugins/partials/contentTemplates';
import getStyles from './config/getStyles';
import colors from './config/colors';
import headings from './config/headings';
// eslint-disable-next-line import/no-cycle
import { SideBarContext } from '../SideBars/SideBarContextProvider';
import { substituteMediaEmbeddedIframes } from './substituteMediaEmbeddedIframes';

export function Ck5SlideEditor({
  background,
  disabled,
  editorContext,
  id,
  isInSideBar = false,
  onReady = null,
}) {
  const t = useFormatMessage();
  const {
    editorContainerRef,
    editorRef,
    isEditorReady,
    isFullScreen,
    onChange,
    setCurrentPlugin,
    setIsEditorReady,
    setIsFullScreen,
    value,
  } = editorContext;
  const { isCk5Loaded } = useContext(SideBarContext);

  const [triggerReset, setTriggerReset] = useState(false);

  useEffect(() => {
    if (
      isEditorReady &&
      editorRef.current.setData &&
      editorRef.current.setData instanceof Function
    ) {
      editorRef.current?.setData(value || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isEditorReady]);

  useEffect(() => {
    if (
      triggerReset &&
      isEditorReady &&
      editorRef.current.setData &&
      editorRef.current.setData instanceof Function
    ) {
      editorRef.current?.setData(triggerReset);
      setTriggerReset(false);
    }
  }, [editorRef, isEditorReady, triggerReset]);

  if (!isCk5Loaded) {
    return null;
  }

  return (
    <div
      ref={editorContainerRef}
      className={c(
        'editor-container flex flex-col flex-1 flex-nowrap',
        disabled && 'opacity-50',
        isFullScreen && 'fullScreenEditor',
      )}
    >
      <div
        className={c('z-10 border-0', `document-editor__toolbar-${id}`)}
        id={`document-editor__toolbar-${id}`}
      />
      <div
        className={c(
          'document-editor__editable-container ck5Slide',
          isInSideBar ? 'side_bar_editor' : 'main_editor',
          background && `${SLIDE_BACKGROUNDS[background]?.className}Editor`,
        )}
      >
        <CKEditor
          ref={editorRef}
          className="ck5-slideviewer"
          config={{
            allowedContent: true,
            contentTemplatesConfig: {
              showExample: false,
              templates: contentTemplates(t),
            },
            exercise: {
              onOpen: () => setCurrentPlugin(plugins.EXERCISE),
            },
            fontBackgroundColor: { colors },
            fontColor: { colors },
            fullScreen: {
              onOpen: () => setIsFullScreen((prev) => !prev),
            },
            heading: {
              options: headings,
            },
            htmlInsert: {
              onOpen: () => setCurrentPlugin(plugins.HTML_INSERT),
            },
            htmlSupport: {
              allow: [
                {
                  attributes: true,
                  classes: true,
                  name: /.*/,
                  styles: true,
                },
              ],
            },
            iframe: {
              toolbar: ['iframe'],
            },
            mediaEmbed: {
              previewsInData: true,
            },
            modal: {
              onOpen: () => setCurrentPlugin(plugins.MODAL),
            },
            ownImage: {
              onOpen: () => setCurrentPlugin(plugins.IMAGE),
            },
            removeBlockStyle: {
              onClose: () => {
                editorRef.current.removeBlockStyle = false;
              },
              onOpen: () => {
                editorRef.current.removeBlockStyle = true;
              },
            },
            source: {
              onOpen: () => setCurrentPlugin(plugins.SOURCE),
            },
            style: {
              definitions: getStyles(t),
            },
            styledLink: {
              onOpen: () => setCurrentPlugin(plugins.LINK),
            },
            table: {
              contentToolbar: [
                'tableColumn',
                'tableRow',
                'mergeTableCells',
                'tableProperties',
                'tableCellProperties',
              ],
            },
          }}
          disabled={disabled}
          editor={window.DecoupledEditor}
          onChange={(event, editor) => {
            if (!disabled) {
              const substitutedResult = substituteMediaEmbeddedIframes(
                editor.getData(),
                setTriggerReset,
              );

              onChange(substitutedResult);
            }
          }}
          onError={(error, { willEditorRestart }) => {
            // eslint-disable-next-line no-console
            console.log({ error });
            if (willEditorRestart) {
              editorRef.current.ui.view.toolbar.element.remove();
            }
          }}
          onReady={(editor) => {
            const toolbarContainer = document.querySelector(
              `#document-editor__toolbar-${id}`,
            );
            toolbarContainer?.appendChild(editor.ui.view.toolbar.element);
            editorRef.current = editor;
            setIsEditorReady(true);
            if (onReady) {
              onReady();
            }
          }}
        />
      </div>
    </div>
  );
}
