import { useContext, useEffect, useState } from 'react';
import { FaLink } from 'react-icons/fa';

import {
  ActionStyledAsButton,
  ConfirmationDialog,
  Dropdown,
  Input,
  InputGroup,
  Label,
  Loader,
  SLIDE_BACKGROUNDS,
} from '@ftrprf/tailwind-components';

import * as SLIDE_TYPES from 'utils/constants/slideTypes';

import useFormatMessage from '../../../../hooks/useFormatMessage';
import useSlide from '../../hooks/useSlide';

import { BackgroundPreview } from './partials/BackgroundPreview';
// eslint-disable-next-line import/no-cycle
import { SideBarContext } from './SideBarContextProvider';

export function GeneralSideBar({ disabled, slideId, studioId }) {
  const t = useFormatMessage();
  const { isCk5 } = useContext(SideBarContext);

  const [changeSlideTypeConfirmationOpen, setChangeSlideTypeConfirmationOpen] =
    useState(false);
  const [selectedType, setSelectedType] = useState(SLIDE_TYPES.TEXT);
  const [copySlideClicked, setCopySlideClicked] = useState(false);
  const [isUpdatingSlideType, setIsUpdatingSlideType] = useState(false);

  const { createQuestion, removeQuestion, slide, update } = useSlide(slideId);

  const hasQuestions = slide?.questions?.length > 0;
  const question = hasQuestions && slide?.questions[0];

  // make sure "copy slide link" button is available when slide changes
  useEffect(() => {
    setCopySlideClicked(false);
  }, [slideId]);

  useEffect(() => {
    if (slide) {
      if (hasQuestions) setSelectedType(SLIDE_TYPES[question.type]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slide]);

  if (!slide) {
    return null;
  }

  async function changeSlideType(type, skipShortCircuit = false) {
    if (!skipShortCircuit && type === selectedType) {
      // eslint-disable-next-line no-console
      console.info('type has not changed, aborting ...');
      return;
    }
    setSelectedType(type);
    setIsUpdatingSlideType(true);

    // change type to text
    if (type === SLIDE_TYPES.TEXT && hasQuestions) {
      removeQuestion(question.id).then(() => {
        setIsUpdatingSlideType(false);
      });
      return;
    }

    // change type from text to question type
    if (type !== SLIDE_TYPES.TEXT && !hasQuestions) {
      createQuestion(type).then(() => {
        setIsUpdatingSlideType(false);
      });
      return;
    }

    // change slide from one question type to the other
    if (type !== SLIDE_TYPES.TEXT && hasQuestions && question.type !== type) {
      await removeQuestion(question.id);

      createQuestion(type).then(() => {
        setIsUpdatingSlideType(false);
      });
    }
  }

  const copyLinkSlide = () => {
    navigator.clipboard.writeText(`slide://${slideId}/${studioId}`);
    setCopySlideClicked(true);
    setTimeout(() => {
      setCopySlideClicked(false);
    }, 1500);
  };

  return (
    <>
      <ConfirmationDialog
        content={t('content-editor.change_question.confirm')}
        isOpen={changeSlideTypeConfirmationOpen}
        onConfirm={() => {
          setChangeSlideTypeConfirmationOpen(false);
          changeSlideType(selectedType, true);
        }}
        onDismiss={() => {
          setChangeSlideTypeConfirmationOpen(false);

          // restore slidetype to original
          setSelectedType(SLIDE_TYPES.MULTIPLE_CHOICE);
        }}
      />
      <div className="">
        <InputGroup>
          <Label>{t('slide-sidebar.slide_title')}</Label>
          <Input
            disabled={disabled}
            onChange={(e) =>
              update({
                title: e.target.value,
              })
            }
            placeholder={t('content-editor.title.placeholder')}
            value={slide.title || ''}
          />
        </InputGroup>
        <InputGroup>
          <Label htmlFor="generalSideBar_slideType">
            {t('slide-sidebar.slide_type')}
            {isUpdatingSlideType && (
              <span className="w-3 h-3 inline-block">
                <Loader />
              </span>
            )}
          </Label>
          <Dropdown
            dataTest="studio-slidetype"
            disabled={disabled || question?.publishedAt || isUpdatingSlideType}
            inputId="generalSideBar_slideType"
            onChange={(newType) => {
              if (question?.type !== newType) {
                setSelectedType(newType);
                setChangeSlideTypeConfirmationOpen(true);
                return;
              }

              changeSlideType(newType);
            }}
            options={Object.values(SLIDE_TYPES).map((type) => ({
              key: type,
              label: t(`slide_type.${type}`),
              value: type,
            }))}
            placeholder={t('content-editor.title.placeholder')}
            value={selectedType}
          />
        </InputGroup>
        {isCk5 && (
          <InputGroup>
            <Label htmlFor="generalSideBar_slideBackground">
              {t('editor.background.slide')}
            </Label>
            <Dropdown
              className="react-select-bg-container"
              classNamePrefix="react-select-bg"
              disabled={disabled || question.publishedAt || isUpdatingSlideType}
              inputId="generalSideBar_slideBackground"
              isSearchable={false}
              onChange={(background) => update({ background })}
              options={Object.values(SLIDE_BACKGROUNDS).map((background) => ({
                key: background.value,
                label: (
                  <BackgroundPreview
                    className="h-full"
                    url={background.previewUrl}
                  />
                ),
                value: background.value,
              }))}
              value={slide.background || SLIDE_BACKGROUNDS.white.value}
            />
          </InputGroup>
        )}

        <div className="flex justify-center mt-4">
          <ActionStyledAsButton
            className="w-max"
            disabled={copySlideClicked}
            iconBefore={FaLink}
            onClick={copyLinkSlide}
            secondary
          >
            {copySlideClicked
              ? t('slide-sidebar.link_copied')
              : t('slide-sidebar.copy_slide_link')}
          </ActionStyledAsButton>
        </div>
      </div>
    </>
  );
}
