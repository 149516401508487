import {
  ActionStyledAsButton,
  DialogActions,
} from '@ftrprf/tailwind-components';
import useFormatMessage from '../../../hooks/useFormatMessage';

export function LinkActions({
  generateTitle,
  isSubmitting,
  isValid,
  setIsDialogShown,
  setIsWarning,
}) {
  const t = useFormatMessage();

  return (
    <DialogActions>
      <ActionStyledAsButton
        onClick={() => {
          setIsDialogShown(false);
          setIsWarning(false);
        }}
        variant="text"
      >
        {t('global.cancel')}
      </ActionStyledAsButton>
      <ActionStyledAsButton
        disabled={isSubmitting || !isValid}
        loading={isSubmitting}
        type="submit"
      >
        {generateTitle()}
      </ActionStyledAsButton>
    </DialogActions>
  );
}
